<template>
  <!-- 培训协议 -->
  <div class="jxEvaluationRecordDetails">
    <van-nav-bar title="历史评价记录详情"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight" 
    />
    <div class="container">
      <div class="topBox">
        <van-row type="flex">
          <van-col span="4">
            <img src="@/assets/img/interview_pic1.png" alt="" class="user_pic_cls">
          </van-col>
          <van-col span="20" class="topRowRight">
            <div class="cell_list">
              <van-cell value="张三" center></van-cell>
            </div>
          </van-col>
        </van-row>
      </div> 
      <div class="topListBox">
        <h2>
          <span>请为以下评估条件输入<strong>1~10分</strong>评价</span>
        </h2>
        <div class="slideBox">
          <div class="slideItem">
            <div class="itemHeader">
              <div class="name">
                <span>职业素养</span>
              </div>
              <div class="score">
                <em>{{ value1 }}</em>
                <span>分</span> 
              </div>
            </div>
            <div class="itemSlide">
              <van-slider bar-height="4px" :max="10" v-model="value1" @change="onChange" />
            </div>
          </div>
          <div class="slideItem">
            <div class="itemHeader">
              <div class="name">
                <span>沟通能力</span>
              </div>
              <div class="score">
                <em>{{ value2 }}</em>
                <span>分</span> 
              </div>
            </div>
            <div class="itemSlide">
              <van-slider bar-height="4px" :max="10" v-model="value2" @change="onChange" />
            </div>
          </div>
          <div class="slideItem">
            <div class="itemHeader">
              <div class="name">
                <span>抗压能力</span>
              </div>
              <div class="score">
                <em>{{ value3 }}</em>
                <span>分</span> 
              </div>
            </div>
            <div class="itemSlide">
              <van-slider bar-height="4px" :max="10" v-model="value3" @change="onChange" />
            </div>
          </div>
          <div class="slideItem">
            <div class="itemHeader">
              <div class="name">
                <span>学习能力</span>
              </div>
              <div class="score">
                <em>{{ value4 }}</em>
                <span>分</span> 
              </div>
            </div>
            <div class="itemSlide">
              <van-slider bar-height="4px" :max="10" v-model="value4" @change="onChange" />
            </div>
          </div>
          <div class="slideItem">
            <div class="itemHeader">
              <div class="name">
                <span>个人魅力</span>
              </div>
              <div class="score">
                <em>{{ value5 }}</em>
                <span>分</span> 
              </div>
            </div>
            <div class="itemSlide">
              <van-slider bar-height="4px" :max="10" v-model="value5" @change="onChange" />
            </div>
          </div>
          <div class="allScore">
            <div class="name">
              <span>总分：{{ value1+value2+value3+value4+value5 }}</span>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value1:1,
      value2:1,
      value3:1,
      value4:1,
      value5:1,
    }
  },
  methods: {
    onClickLeft () {
        this.$router.push('/jxEvaluationRecord')
    },
    onClickRight () { 

    },
    onChange(value) {
    },
  }
}
</script>

<style lang="less" scoped>
.jxEvaluationRecordDetails {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .container{
    .topBox{
      padding: 0.35rem 0.32rem;
      background: #fff;
      .user_pic_cls{
        width: 100%;
        max-width: 100%;
        display: block;
      }
      .topRowRight{
        display: flex;
        align-items: center;
        position: relative;
        .cell_list{
          width: 100%;
          /deep/.van-cell__label{
            margin-top: 0;
          }
          /deep/.van-cell__value{
            span{
              font-size: 36px;
              font-weight: bold;
            }
          }
        }
        /deep/.van-cell{
          padding: 0;
          padding-left: 0.3rem;
        }
      }
    }
  } 
  .slideBox{
    margin-top: 36px;
  }
  .topListBox{
    width: 100%;
    background: #fff;
    padding-bottom: 44px;
    margin-top: 24px;
    h2{
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 0.32rem;
      border-bottom: 1px solid #cccccc;
      span{
        font-size: 32px;
        font-weight: bold;
        color: #000;
        strong{
          color: #2B8DF0;
        }
      }
    }
    .slideItem{
      padding-bottom: 50px;
      border-bottom: 1px solid #ccc;
      margin: 0 0.32rem;
      margin-top: 0.28rem;
    }
    .allScore{
      width: 100%;
      padding: 0 0.32rem;
      margin-top: 0.23rem;
      .name{
        span{
          font-size: 32px;
          font-weight: bold;
          color: #000;
          position: relative;
          &::before{
            content:'*';
            color: red;
            position: absolute;
            top: -6px;
            left: -0.25rem;
            font-size: 32px;
          }
        }
      }
    }
    .itemHeader{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name{
        span{
          font-size: 32px;
          font-weight: bold;
          color: #000;
          position: relative;
          &::before{
            content:'*';
            color: red;
            position: absolute;
            top: -6px;
            left: -15px;
            font-size: 32px;
          }
        }
      }
      .score{
        em{
          font-size: 42px;
          font-weight: bold;
          color: #2B8DF0;
          font-style: normal;
        }
        span{
          font-size: 28px;
          color: #333;
          margin-left: 15px;
          vertical-align: text-bottom;
        }
      }
    }
    .itemSlide{
      width: 100%;
      margin-top: 36px;
    }
  }  
}
</style>